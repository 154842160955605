<template>
  <div>
    <LetterId
      v-if="
        $router.currentRoute.path ==
        '/' + role + '/secretariat/inbox/letterInfo/' + $route.params.id
      "
      :type="'inbox'"
      @save="save"
      @removeSave="removeSave"
      @archive="archive"
      @removeArchive="removeArchive"
    />
    <router-view />
  </div>
</template>
<script>
import Axios from "axios";
import LetterId from "../../../components/letters/LetterId.vue";
export default {
  components: {
    LetterId,
  },
  data() {
    return {
      role:""
    };
  },
  methods: {
    save(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/mark",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeSave(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeMark",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    archive(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/archive",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeArchive(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeArchive",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
  },
};
</script>
