<template>
  <div>
    <div
      class="letterInfo pb-6 pe-0"
      :style="type != 'internal' ? 'padding-left:35px' : ''"
    >
      <v-card class="text-center pa-10 cards" v-if="!isLoaded">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <circle-4></circle-4>
          <h6 class="mt-6">در حال بارگذاری ...</h6>
        </div>
      </v-card>
      <v-card v-else class="letter-card">
        <div class="letter-header grey--text text--darken-2">
          <v-row>
            <v-col>
              <div class="mb-5">
                <h5>
                  <v-icon class="pe-3">$EditUser</v-icon> فرستنده:
                  {{ letterInfo.sender }}
                </h5>
              </div>
              <div>
                <h5 class="d-flex">
                  <div v-if="letterInfo.type == 'internal'" class="d-flex">
                    <v-icon class="pe-3">$TickUser</v-icon> گیرنده:

                    <h5 v-if="letterInfo.status != 'پیش نویس'" class="ms-1">
                      {{ letterInfo.receiver }}
                    </h5>
                    <span v-else class="ms-1 mt-1">
                      <span
                        v-if="
                          letterInfo.externalReceiverDraft
                            ? letterInfo.externalReceiverDraft.length == 1
                            : ''
                        "
                      >
                        {{ letterInfo.externalReceiverDraft[0].name }}
                      </span>

                      <v-tooltip v-else max-width="300" top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{
                              letterInfo.externalReceiverDraft
                                ? letterInfo.externalReceiverDraft[0].name
                                : null
                            }}
                            و
                            {{
                              letterInfo.externalReceiverDraft
                                ? letterInfo.externalReceiverDraft.length - 1
                                : null
                            }}
                            نفر دیگر
                          </span>
                        </template>
                        <span class="fs-xsmall">
                          {{
                            letterInfo.externalReceiverDraft
                              ? letterInfo.externalReceiverDraft
                                  .map((x) => x.name)
                                  .join(" , ")
                              : ""
                          }}
                        </span>
                      </v-tooltip>
                    </span>
                  </div>
                  <div v-else class="d-flex">
                    <v-icon class="pe-3">$TickUser</v-icon> گیرنده:

                    <h5 v-if="letterInfo.receiver" class="ms-1">
                      {{ letterInfo.receiver }}
                    </h5>
                  </div>
                </h5>
              </div>
              <div v-if="letterInfo.assigne" class="mt-5">
                <h5>
                  <v-icon class="pe-3">mdi-arrow-left-circle-outline</v-icon>
                  تخصیص به:
                  {{ letterInfo.assigne }}
                </h5>
              </div>
              <div class="mb-6 mt-10">
                <span
                  >تاریخ ارسال:
                  <span>{{
                    letterInfo.date ? persianMonthName(letterInfo.date) : "-"
                  }}</span></span
                >
              </div>
              <div class="mb-6">
                <span
                  >شماره نامه:
                  {{ letterInfo.letterNo ? letterInfo.letterNo : "-" }}</span
                >
              </div>
              <div v-if="type != 'internal'" class="mb-6">
                <span
                  >شماره بایگانی:
                  {{ letterInfo.archiveNo ? letterInfo.archiveNo : "-" }}</span
                >
              </div>
              <div class="mb-6">
                <span
                  >تاریخ پیگیری نامه:
                  <span>{{
                    letterInfo.followingDate
                      ? persianMonthName(letterInfo.followingDate)
                      : "-"
                  }}</span></span
                >
              </div>
              <div v-if="letterInfo.orderId" class="mb-6">
                <span
                  >استعلام مرتبط با نامه:
                  <span>{{ letterInfo.orderCode }}</span></span
                >
              </div>
              <div class="d-flex">
                <div :class="type == 'inbox' ? 'purple-badge' : 'blue-badge'">
                  <span>{{ computedType }}</span>
                </div>
                <div
                  :class="
                    letterInfo.status == 'ارسال شده'
                      ? 'green-badge2'
                      : letterInfo.status == 'پیش نویس'
                      ? 'orange-badge'
                      : 'blue-badge'
                  "
                  class="ms-2 me-2"
                >
                  <span>{{ letterInfo.status }}</span>
                </div>
              </div>
            </v-col>
            <v-col class="d-flex flex-column justify-space-between">
              <div class="d-flex flex-row justify-content-end">
                <div class="ms-4">
                  <a id="download"></a>
                  <!--<v-btn
                    v-if="type != 'inbox'"
                    height="35"
                    width="150"
                    class="pe-5 ps-5 blue-borderedBtn"
                    @click="getPdf()"
                    :loading="pdfLoading"
                  >
                    <template v-slot:loader>
                      <span class="white--text fs-xsmall d-flex flex-row">
                        <span> چند لحظه صبر کنید... </span>
                      </span>
                    </template>
                    <span> خروجی pdf </span></v-btn
                  >-->

                  <v-btn
                    :disabled="!letterInfo.letterFile"
                    @click="
                      download(letterInfo.letterFile, letterInfo.letterFileName)
                    "
                    v-if="type == 'inbox'"
                    height="35"
                    class="pe-5 ps-5 purple-borderedBtn"
                    >دریافت فایل متن نامه</v-btn
                  >
                </div>
                <v-dialog v-model="comingSoon" width="400">
                  <v-card class="modal-card">
                    <div class="text-center border-box pb-4 ps-4 pe-4">
                      این ویژگی به زودی فعال می‌شود.
                      <div class="text-center">
                        <v-btn
                          :class="
                            type == 'inbox'
                              ? 'purple-borderedBtn'
                              : 'blue-borderedBtn'
                          "
                          class="mt-6"
                          @click="comingSoon = false"
                          >فهمیدم!</v-btn
                        >
                      </div>
                    </div>
                  </v-card>
                </v-dialog>
              </div>
              <div class="d-flex flex-row justify-content-end">
                <v-btn
                  @click="saveChange()"
                  class="me-4"
                  :class="
                    !letterInfo.marked ? 'blue-borderedBtn' : 'blue-mainBtn '
                  "
                  height="44"
                  ><v-icon class="pe-2">$Save</v-icon>ذخیره</v-btn
                >
                <v-btn
                  @click="archiveChange()"
                  :class="
                    !letterInfo.archive
                      ? 'blue-borderedBtn'
                      : 'blue-mainBtn readonly-box'
                  "
                  height="44"
                  width="155"
                  ><v-icon class="pe-2">$Archive</v-icon
                  ><span>{{
                    !letterInfo.archive ? "ارسال به بایگانی" : "بایگانی شده"
                  }}</span></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
        <hr color="#eee" class="my-7" />
        <div class="grey--text text--darken-2">
          <v-row>
            <v-col>
              <div class="d-flex flex-row">
                <span class="pt-1">موضوع نامه: {{ letterInfo.title }}</span>
                <!-- <div class="orange-badge ms-4 me-4">
                  {{ letterInfo.subject.tag }}
                </div> -->
              </div>
            </v-col>
            <v-col v-if="letterInfo.isResponse" class="text-end">
              <span
                >پاسخ به نامه شماره
                <u style="text-decoration-color: #959ab1">{{
                  letterInfo.responseArchiveNo
                }}</u></span
              >
            </v-col>
          </v-row>
          <div class="mt-6">
            <div
              class="border-box text-right"
              v-if="type != 'inbox' ? letterInfo.text : letterInfo.abstract"
            >
              <span
                class="letter-text"
                v-html="type == 'inbox' ? letterInfo.abstract : letterInfo.text"
              ></span>
            </div>
            <div v-if="letterInfo.letterFile" class="mt-6">
              <div class="d-flex flex-row flex-wrap">
                <div class="file-box me-8 mb-6">
                  <div class="image-box">
                    <v-img :src="computedLetterFileImage"></v-img>
                  </div>
                  <div class="file-info grey--text text--darken-1 pa-4">
                    <span>{{
                      letterInfo.letterFileName.length > 30
                        ? letterInfo.letterFileName.slice(0, 30) + "..."
                        : letterInfo.letterFileName
                    }}</span>
                    <div class="mt-5 d-flex flex-row justify-space-between">
                      <div>
                        <span dir="ltr">{{ letterInfo.letterFileSize }}</span>
                      </div>
                      <div>
                        <span
                          @click="showFile(letterInfo.letterFile)"
                          class="ms-4 me-4 pointer"
                          v-if="
                            letterInfo.letterFileType == 'image/png' ||
                            letterInfo.letterFileType == 'image/jpeg'
                          "
                          ><v-icon
                            :class="
                              type == 'inbox' ? 'purple-icon' : 'blue-icon'
                            "
                            >$View</v-icon
                          ></span
                        >
                        <span
                          @click="
                            download(
                              letterInfo.letterFile,
                              letterInfo.letterFileName
                            )
                          "
                          class="pointer"
                          ><v-icon
                            :class="
                              type == 'inbox' ? 'purple-icon' : 'blue-icon'
                            "
                            >$Download</v-icon
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="letterInfo.files ? letterInfo.files.length : null">
            <hr color="#eee" class="my-7" />
            <div>
              <h5
                v-if="letterInfo.files && letterInfo.files.length"
                class="mb-8 ms-1 me-1"
              >
                تعداد فایل‌های پیوست: {{ letterInfo.files.length }} عدد
              </h5>
              <div class="d-flex flex-row flex-wrap">
                <div
                  class="file-box me-8 mb-6"
                  v-for="(file, index) in letterInfo.files"
                  :key="index"
                >
                  <div class="image-box">
                    <v-img
                      :src="chooseFileImageType(file.url, file.type)"
                    ></v-img>
                  </div>
                  <div class="file-info grey--text text--darken-1 pa-4">
                    <span>{{
                      file.name.length > 25
                        ? file.name.slice(0, 25) + "..."
                        : file.name
                    }}</span>
                    <div class="mt-5 d-flex flex-row justify-space-between">
                      <div>
                        <span dir="ltr">{{ file.size }}</span>
                      </div>
                      <div>
                        <span
                          @click="showFile(file.url)"
                          class="ms-4 me-4 pointer"
                          ><v-icon
                            :class="
                              type == 'inbox' ? 'purple-icon' : 'blue-icon'
                            "
                            v-if="
                              file.type == 'image/png' ||
                              file.type == 'image/jpeg'
                            "
                            >$View</v-icon
                          ></span
                        >
                        <span
                          @click="download(file.url, file.name)"
                          class="pointer"
                          ><v-icon
                            :class="
                              type == 'inbox' ? 'purple-icon' : 'blue-icon'
                            "
                            >$Download</v-icon
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr color="#eee" />
          <v-row>
            <v-col class="d-flex flex-row">
              <div
                v-for="(tag, index) in letterInfo.tags"
                :key="index"
                class="blue-badge2 me-2 my-2"
              >
                <span>{{ tag }}</span>
              </div>
            </v-col>
            <v-col class="text-left"> </v-col>
          </v-row>
        </div>
        <v-row class="mb-2">
          <v-col v-if="role == 'Manager' || userId == letterInfo.assigneId">
            <div class="custom-input-blue">
              <div class="d-flex">
                <v-select
                  outlined
                  v-model="letterInfo.assigneId"
                  :items="selectEmployees"
                  height="40"
                  dense
                  item-text="text"
                  item-value="id"
                >
                  <template slot="label">
                    <div style="font-size: 14px">تخصیص به</div>
                  </template>
                  <template slot="append">
                    <v-icon
                      @click="letterInfo.assigneId = ''"
                      v-if="letterInfo.assigneId"
                      >mdi-close</v-icon
                    >
                    <v-icon>mdi-menu-down</v-icon>
                  </template>
                </v-select>
                <v-btn
                  @click="removeAssigne()"
                  class="float-end red-mainBtn ms-2"
                  height="40"
                  v-if="letterInfo.assigneId"
                >
                  <v-icon class="px-1">mdi-account-remove-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="saveAssigne()"
                  class="float-end ms-2 green-mainBtn"
                  height="40"
                >
                  <v-icon class="px-1"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="custom-input-blue">
              <div class="d-flex flex-row">
                <v-text-field
                  outlined
                  dense
                  height="40"
                  v-model="editFollowingDate"
                  id="followingDate"
                >
                  <template slot="label">
                    <div style="font-size: 14px">تاریخ پیگیری بعدی</div>
                  </template>
                </v-text-field>

                <v-btn
                  class="ms-2 blue-borderedBtn"
                  id="followingDate"
                  width="40"
                  min-width="40"
                  height="40"
                  ><v-icon>$Calendar</v-icon></v-btn
                >
                <date-picker
                  element="followingDate"
                  v-model="editFollowingDate"
                  :min="currentDate"
                  color="#4c81c9"
                >
                </date-picker>
                <v-btn
                  @click="saveDate()"
                  class="float-end ms-2 green-mainBtn"
                  height="40"
                  :disabled="!editFollowingDate"
                >
                  <v-icon class="px-1"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                </v-btn>
              </div>
            </div></v-col
          >
        </v-row>
        <div class="action d-flex flex-row justify-space-between">
          <div class="d-flex flex-row">
            <v-btn
              v-if="
                letterInfo.status == 'ارسال شده' && letterInfo.subType != 'send'
              "
              @click="reply()"
              class="me-4"
              :class="type == 'inbox' ? 'purple-mainBtn' : 'blue-mainBtn'"
              height="44"
              ><v-icon class="pe-2">$Messages</v-icon>ارسال پاسخ</v-btn
            >
            <v-btn
              v-if="computedCondition"
              @click="edit()"
              :class="
                type == 'inbox' ? 'purple-borderedBtn' : 'blue-borderedBtn'
              "
              height="44"
              ><v-icon
                class="pe-2"
                :class="type == 'inbox' ? 'purple-icon' : 'blue-icon'"
                >$Pen</v-icon
              >ویرایش</v-btn
            >
          </div>
          <div>
            <v-btn
              :to="
                '/' +
                role +
                '/secretariat/' +
                type +
                '/letterInfo/relatedLetters/' +
                $route.params.id
              "
              :class="
                type == 'inbox' ? 'purple-borderedBtn' : 'blue-borderedBtn'
              "
              height="44"
              ><v-icon
                :class="type == 'inbox' ? 'purple-icon' : 'blue-icon'"
                class="pe-2"
                >$LetterPlus</v-icon
              >نامه‌های مرتبط</v-btn
            >
          </div>
        </div>
      </v-card>
      <v-dialog v-model="showBig">
        <v-card class="modal-card">
          <div class="image-box">
            <img :src="selectedFile" alt="" style="height: 100%" />
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";
import Axios from "axios";
import { Circle4 } from "vue-loading-spinner";
export default {
  props: ["type"],
  components: {
    datePicker: VuePersianDatetimePicker,
    Circle4,
  },
  data() {
    return {
      currentDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      role: "",
      selectedFile: {},
      showBig: false,
      letterInfo: {
        letterFile: "",
        letterFileName: "",
        letterFileSize: "",
        letterFileType: "",
      },
      isLoaded: false,
      comingSoon: false,
      gettingCodes: false,
      pdfUrl: "",
      pdfLoading: false,
      monthsName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      userId: "",
      selectEmployees: [],
      editFollowingDate: "",
    };
  },
  computed: {
    computedLetterFileImage() {
      if (
        this.letterInfo.letterFileType == "image/jpeg" ||
        this.letterInfo.letterFileType == "image/png"
      ) {
        return this.letterInfo.letterFile;
      } else if (this.letterInfo.letterFileType == "application/pdf") {
        return "https://apksshare.com/wp-content/uploads/2021/06/Image-to-PDF-Converter-Free-JPG-to-PDF-APK-MOD-Download-2.3.4.png";
      } else if (
        this.letterInfo.letterFileType ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvCi0I-vSsvxIKLa9BKrRCJeH4bBXKwkqNUMz-60tYH8gHZfTUFxK6lkLqoXoMaaLj2dE&usqp=CAU";
      } else {
        return "https://i.pinimg.com/originals/c4/8e/f0/c48ef06a13a33833e9f766b2c191329e.png";
      }
    },
    computedType() {
      if (this.type == "inbox") {
        return "ورودی";
      }
      if (this.type == "outbox") {
        return "خروجی";
      } else {
        return "سازمانی";
      }
    },
    computedCondition() {
      if (
        this.userId == this.letterInfo.assigneId ||
        (this.letterInfo.type == "internal" &&
          this.userId == this.letterInfo.receiverId)
      ) {
        if (this.role == "Manager") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    getEmployees() {
      this.isLoaded = false;
      this.employeesLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/internals",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.isLoaded = true;
          this.selectEmployees = res.data.map((x) => {
            return {
              id: x.id,
              text: x.name,
            };
          });
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    saveAssigne() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/assigneTo",
        {
          letterId: this.$route.params.id,
          assigneId: this.letterInfo.assigneId,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.getInfo();
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeAssigne() {
      this.vLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeAssigne",
        {
          letterId: this.$route.params.id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.toast(res.data, "success");

          this.getInfo();

          this.vLoading = false;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.vLoading = false;
        });
    },
    saveDate() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/manageFollowing",
        {
          letterId: this.$route.params.id,
          followingDate: this.editFollowingDate,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.getInfo();
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    saveChange() {
      !this.letterInfo.marked
        ? this.$emit("save", this.$route.params.id)
        : this.$emit("removeSave", this.$route.params.id);
      setTimeout(() => {
        this.getInfo();
      }, 200);
    },
    persianMonthName(date) {
      let year = date.split("/")[0];
      let month = date.split("/")[1];
      let day = date.split("/")[2];
      let monthName;
      monthName = this.monthsName[Number(month) - 1];
      return day + " " + monthName + " " + year;
    },
    archiveChange() {
      !this.letterInfo.archive
        ? this.$emit("archive", this.$route.params.id)
        : this.$emit("removeArchive", this.$route.params.id);
      setTimeout(() => {
        this.getInfo();
      }, 200);
    },
    getInfo() {
      this.isBusy = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/getInfo",
        {
          letterId: this.$route.params.id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 200) {
            this.letterInfo = res.data;
            this.editFollowingDate = this.letterInfo.followingDate;
            this.isLoaded = true;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.isLoaded = true;
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
          this.isLoaded = true;
        });
    },
    reply() {
      this.$router.push(
        "/" +
          this.role +
          "/secretariat/" +
          this.type +
          "/reply/" +
          this.$route.params.id
      );
    },
    edit() {
      if (this.letterInfo.status == "پیش نویس") {
        this.$router.push(
          "/" +
            this.role +
            "/secretariat/drafts/completeDraft/" +
            this.$route.params.id
        );
      } else {
        this.$router.push(
          "/" +
            this.role +
            "/secretariat/" +
            this.type +
            "/edit/" +
            this.$route.params.id
        );
      }
    },
    getPdf() {
      this.pdfLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/generatePDF",
        {
          letterId: this.$route.params.id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.pdfUrl = res.data;
            setTimeout(() => {
              var button = document.getElementById("download");
              button.setAttribute("href", this.pdfUrl);
              button.setAttribute("target", "_blank");
              button.setAttribute("download", this.letterInfo.letterNo);
              button.click();
              this.pdfLoading = false;
            }, 2000);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
            this.pdfLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.pdfLoading = false;
        });
    },
    showFile(file) {
      this.selectedFile = file;
      this.showBig = true;
    },
    download(fileUrl, fileName) {
      var a = document.createElement("a");
      a.setAttribute("href", fileUrl);
      a.setAttribute("download", fileName);
      a.setAttribute("target", "blank");
      a.click();
    },
    chooseFileImageType(url, type) {
      if (type == "image/jpeg" || type == "image/png") {
        return url;
      } else if (type == "application/pdf") {
        return "https://apksshare.com/wp-content/uploads/2021/06/Image-to-PDF-Converter-Free-JPG-to-PDF-APK-MOD-Download-2.3.4.png";
      } else if (
        type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvCi0I-vSsvxIKLa9BKrRCJeH4bBXKwkqNUMz-60tYH8gHZfTUFxK6lkLqoXoMaaLj2dE&usqp=CAU";
      } else {
        return "https://i.pinimg.com/originals/c4/8e/f0/c48ef06a13a33833e9f766b2c191329e.png";
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.userId = localStorage.getItem("userId");
    this.getEmployees();
    this.getInfo();
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../assets/fonts/IRANSans(FaNum).ttf");
}
.letterInfo {
  .letter-card {
    border-radius: 17px;
    padding: 20px 20px 20px 25px;
    margin-top: -10px;
    .letter-header {
      @mixin updown-btn($color) {
        height: 35px !important;
        width: 35px !important;
        min-width: 35px !important;
        padding: 0 !important;
        border: 1px solid $color;
        border-radius: 8px;
        background-color: #fff;
        path {
          stroke: $color;
        }
      }
      .purple-updown-btn {
        @include updown-btn(#7e57c2);
      }
      .blue-updown-btn {
        @include updown-btn(#6092cd);
      }
      .blue-borderedBtn {
        path {
          stroke: #4c81c9;
        }
      }
    }
    .letter-text {
      line-height: 30px;
      font-size: 15px;
    }
    .file-box {
      width: 250px;
      .v-image {
        border-radius: 12px 12px 0 0;
        background-size: cover;
        height: 190px;
      }
      .file-info {
        background-color: #f6f1fe;
        border-radius: 0 0 12px 12px;
      }
    }
  }
  .purple-icon {
    path {
      stroke: #7e57c2 !important;
    }
  }
  .blue-icon {
    path {
      stroke: #6092cd !important;
    }
  }
  .custom-input-blue {
    input {
      padding-right: 10px !important;
    }
    .v-text-field__details {
      display: none !important;
    }
    .v-text-field .v-input__control,
    .v-text-field fieldset {
      border-radius: 12px !important;
      border-color: #4c81c9 !important;
    }
    .v-label {
      color: #4c81c9 !important;
    }
    .v-icon {
      path {
        stroke: #4c81c9 !important;
      }
    }
  }
}
.v-dialog {
  width: fit-content;
}
.modal-card {
  padding: 15px;
  overflow: hidden;
  border-radius: 12px !important;
  .image-box {
    height: 550px;
    pointer-events: none;
  }
  .border-box {
    font-size: 16px;
  }
}
</style>
